<template>
  <div v-if="news && news.length" class="slider__mobile slider__mobile-news">
    <swiper :options="options">
      <swiper-slide v-for="(item, index) in news" :key="index">
        <div class="slide__mobile">
          <router-link
            :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
            class="_item"
          >
            <div class="_item__img">
              <div class="img__container">
                <img :src="item.headImg | image($store.state.api)" :alt="item.headImg | image_alt" />
              </div>
            </div>
            <div class="news__mayor__header _item__header">
              <div v-if="item.category && item.category.title" class="_item__category">
                {{ item.category.title }}
              </div>
              <div class="_item__time" v-if="item.date_publication">
                <time :time="item.date_publication | robotDate">
                  {{ item.date_publication | humanDateRu }}
                </time>
              </div>
            </div>
            <div v-if="item.title" class="_item__title">
              {{ item.title }}
            </div>
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
  name: 'SliderComponent',
  props: {
    news: Array
  },
  data () {
    return {
      loading: false,
      options: {
        slidesPerView: 1.2,
        loop: false,
        clickable: true,
        speed: 800,
        spaceBetween: 16,
        breakpoints: {
          360: {
            slidesPerView: 1.5
          },
          440: {
            slidesPerView: 1.8
          },
          480: {
            slidesPerView: 2
          }
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  }
}
</script>

<style lang="stylus">
@import "~@/styles/parts/swiper.styl"
</style>
