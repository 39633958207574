<template>
  <main class="main">
    <div class="main__inner">
      <div v-if="pageLoading" class="page__loading">
        <LoadingIndicator title="Загрузка" />
      </div>
      <template v-else>
        <section v-if="home_page.this_department && hasText(home_page.this_department.about)" class="section">
          <h3> О ведомстве </h3>
          <EditorJSComponent
            :text="JSON.parse(home_page.this_department.about)"
            v-if="isJson(home_page.this_department.about) && JSON.parse(home_page.this_department.about)"
            :class="{ 'dept__about--hide': textHide }"
          />
          <span v-else-if="home_page.this_department.about">{{ home_page.this_department.about }}</span>
          <a @click="textHide = !textHide" class="link__highlighted" href="#">
            <template v-if="textHide">Подробнее.</template>
            <template v-else>Скрыть</template>
          </a>
        </section>
        <section v-if="home_page.News && home_page.News.data && home_page.News.data.length" class="section">
          <h3> Новости </h3>
          <div class="_items_list no-mobile">
            <router-link
              :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
              class="_item" v-for="(item, index) in home_page.News.data" :key="index"
            >
              <div class="_item__img">
                <div class="img__container">
                  <img :src="item.headImg | image($store.state.api)" :alt="item.headImg | image_alt" />
                </div>
              </div>
              <div class="news__mayor__header _item__header">
                <div v-if="item.category && item.category.title" class="_item__category">
                  {{ item.category.title }}
                </div>
                <div class="_item__time" v-if="item.date_publication">
                  <time :time="item.date_publication | robotDate">
                    {{ item.date_publication | humanDateRu }}
                  </time>
                </div>
              </div>
              <div v-if="item.title" class="_item__title">
                {{ item.title }}
              </div>
            </router-link>
          </div>
          <div class="slider__mobile">
            <SliderComponent :news="home_page.News.data" />
          </div>
          <router-link :to="{ name: 'news' }" class="link__highlighted show-all__link">
            Все новости
          </router-link>
        </section>
        <section v-if="home_page.Services && home_page.Services.length" class="section">
          <h3> Услуги </h3>
          <div class="services">
            <div class="services__list">
              <router-link
                :to="{
                  name: 'service',
                  params: { id: item.id },
                }"
                v-for="(item, index) in home_page.Services"
                :key="index"
                class="services__item"
              >
                <span v-html="item.icon" class="service__icon">
                </span>
                <span v-if="item.subtitle" class="service__title">{{ item.subtitle }}</span>
              </router-link>
            </div>
            <router-link :to="{ name: 'services' }" class="link__highlighted show-all__link">
              Все услуги
            </router-link>
          </div>
        </section>
        <section v-if="home_page.this_department" class="section">
          <h3> Контактная информация </h3>
          <div class="contacts">
            <div class="contacts__list">
              <div v-if="home_page.this_department.address" class="contacts__item">
                <div class="contact__title">
                  Адрес:
                </div>
                <div class="contact__info">
                  {{ home_page.this_department.address }}
                </div>
              </div>
              <template v-if="home_page.this_department.department_info">
                <div v-if="home_page.this_department.department_info.phone" class="contacts__item">
                  <div class="contact__title">
                    Телефоны:
                  </div>
                  <div class="contact__info">
                    {{ home_page.this_department.department_info.phone }}
                  </div>
                </div>
                <div v-if="home_page.this_department.department_info.email" class="contacts__item">
                  <div class="contact__title">
                    Электронная почта:
                  </div>
                  <a :href="`mailto:${home_page.this_department.department_info.email}`">
                    {{ home_page.this_department.department_info.email }}
                  </a>
                </div>
              </template>
            </div>
            <router-link :to="{ name: 'contacts' }" class="link__highlighted show-all__link">
              Подробнее
            </router-link>
          </div>
          <BannersComponent
            v-if="banners && banners.data"
            :banners="banners.data"
          />
        </section>
      </template>
    </div>
  </main>
</template>

<script>
import BannersComponent from '@/components/BannersComponent.vue'
import EditorJSComponent from 'components/EditorJSComponent.vue'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import SliderComponent from '@/components/SliderComponent.vue'

export default {
  name: 'DepartmentHomePage',
  // при обновлении страницы почему-то запрос не делается
  // async asyncData ({ store, apollo }) {
  //   await store.dispatch('GET_HOME_PAGE', apollo)
  // },
  data () {
    return {
      pageLoading: true,
      textHide: true
    }
  },
  created () {
    this.$store.dispatch('GET_HOME_PAGE', this.$apollo.provider).finally(() => {
      this.pageLoading = false
    })
  },
  computed: {
    home_page () {
      return this.$store.state.home_page
    },
    banners () {
      return this.$store.state.banners
    }
  },
  methods: {
    hasText (text) {
      if (this.isJson(text)) {
        const parsedText = JSON.parse(text)
        return !!(parsedText && parsedText.blocks && parsedText.blocks.length)
      }
      return !!text
    },
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.banners = {}
    next()
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    BannersComponent,
    SliderComponent,
    EditorJSComponent,
    LoadingIndicator
  }
}
</script>

<style lang="stylus">
@import "~@/styles/mixins/textcut.styl"
@import "~@/styles/parts/minister.styl"
@import "~@/styles/parts/contacts.styl"

.dept__about--hide {
  textcut(3)
}

.services {
  &__list {
    display flex
    align-items flex-start
    flex-direction column
    gap 24px
  }

  &__item {
    display flex
    align-items center
    gap 16px
  }

  .service__icon {
    display flex
    flex 0 0 24px

    svg {
      width 24px
      height 24px
    }
  }
}
</style>
